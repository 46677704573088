<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="Create Leave Request"
            width="600"
            no-footer
            :closable="closable"
            class="createRequest"
            :before-close="handleReset"
        >
            <Form
                ref="leaveForm"
                :model="leaveForm"
                :loading="isLoading"
                label-position="left"
                class="create-time-dispute-wrapper"
            >
                <Row>
                    <Col span="">
                        <FormItem label="Leave Mode">
                            <Select
                                v-model="leaveForm.mode"
                                style="width: 200px"
                                :value="leaveForm.mode"
                                default-label="FULL DAY"
                            >
                                <Option v-for="item in leaveModes()" :key="item.value" :value="item.value">{{
                                    item.label
                                }}</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
                <Row v-if="leaveForm.mode === 'FULL'">
                    <Col span="12">
                        <FormItem label="Start Date" prop="startDate">
                            <DatePicker
                                v-model="leaveForm.startDate"
                                type="date"
                                format="dd-MMM-yyyy"
                                @on-change="onDateStartChange"
                            ></DatePicker>
                        </FormItem>
                    </Col>
                    <Col>
                        <FormItem label="End Date" prop="endDate">
                            <DatePicker
                                v-model="leaveForm.endDate"
                                format="dd-MMM-yyyy"
                                transfer
                                :disabled="isDisabled"
                                :loading="isLoading"
                                @on-change="onDateEndChange"
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row v-if="leaveForm.mode !== 'FULL'">
                    <Col span="12">
                        <FormItem label="Date" prop="startDate">
                            <DatePicker
                                v-model="leaveForm.startDate"
                                type="date"
                                format="dd-MMM-yyyy"
                                @on-change="onDateStartChange"
                            ></DatePicker>
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span="12">
                        <FormItem label="Leave Type" prop="requestedStartTime">
                            <v-radio-group v-model="leaveForm.leaveType">
                                <v-radio value="vl">
                                    <template slot="label">
                                        &nbsp;Vacation Leave&nbsp;<b>({{ leaves.vl }})</b>
                                    </template>
                                </v-radio>
                                <v-radio value="sl">
                                    <template slot="label">
                                        &nbsp;Emergency/Sick Leave&nbsp;<b>({{ leaves.sl }})</b>
                                    </template></v-radio
                                >
                                <v-radio label="Appreciation Leave" value="al"
                                    ><template slot="label">
                                        &nbsp;Appreciation Leave&nbsp;<b>({{ leaves.al }})</b>
                                    </template></v-radio
                                >
                            </v-radio-group>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="Reason for request (optional)">
                            <Input
                                v-model="leaveForm.reason"
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 5 }"
                                placeholder="Enter something..."
                                style="width: 400px"
                            />
                        </FormItem>
                    </Col>
                </Row>
                <div>
                    <Button class="pt-btn-2" style="margin-right: 10px" @click="handleCancel"> CANCEL </Button>
                    <Button class="pt-btn-3" :loading="isLoading" @click="handleAddLeaveRequest"> SUBMIT </Button>
                </div>
            </Form>
        </Modal>
    </div>
</template>
<script>
import { postLeaves, getLeavesInfo } from '@/services/leave.services';
import moment from 'moment';
import { canHalfDayLeave, leaveModes } from '@/components/Requests/Dev/Leave/LeaveRequest/utils/helper.ts';

export default {
    data() {
        return {
            leaves: {
                al: 0,
                sl: 0,
                vl: 0,
            },
            attendanceLogData: null,
            isLoading: false,
            options: null,
            closable: false,
            leaveForm: {
                startDate: '',
                endDate: '',
                leaveType: null,
                reasons: '',
                mode: null,
            },
            ruleInline: {
                startDate: [
                    {
                        required: true,
                        message: 'Please fill in start date',
                        trigger: 'change',
                        type: 'string',
                    },
                ],
                endDate: [
                    {
                        required: true,
                        message: 'Please fill in end date',
                        trigger: 'change',
                    },
                ],
            },
            ruleInlineWithCreatedAt: {
                startDate: [
                    {
                        required: true,
                        message: 'Please fill in start date',
                        trigger: 'change',
                    },
                ],
                endDate: [
                    {
                        required: true,
                        message: 'Please fill in end date',
                        trigger: 'change',
                    },
                ],
            },
            isDisabled: false,
            showModal: false,
            userId: null,
        };
    },
    created() {
        this.options = {
            disabledDate(date) {
                // const isCurrentDate = date && moment(date).format('DD-MMM-YYYY') == moment().format('DD-MMM-YYYY');
                // return isCurrentDate ? false : date && date.valueOf() > Date.now() - 86400000;
                // return true;
                console.log('date: ', date);
                return Date.now();
            },
        };
    },
    async mounted() {
        const { data } = await getLeavesInfo();
        this.leaves.sl = data['remaining-sick-leave'];
        this.leaves.al = data['remaining-appreciation-leave'];
        this.leaves.vl = data['remaining-vacation-leave'];
    },
    methods: {
        canEmployeeHalfDayLeave(mode, start, end) {
            return canHalfDayLeave(mode, start, end);
        },
        leaveModes() {
            return leaveModes;
        },
        async handleAddLeaveRequest() {
            this.$refs.leaveForm.validate((valid) => {
                console.log('valid: ', valid);
                if (valid) {
                    this.addLeaveRequest();
                } else {
                    this.$Message.error('Fill in the required fields');
                }
            });
        },

        addLeaveRequest() {
            if (!this.leaveForm.startDate || !this.leaveForm.endDate) {
                this.$Message.error('Input Start Date and End Date');
            } else if (!this.leaveForm.leaveType) {
                this.$Message.error('Input Leave Type');
            } else {
                this.isLoading = true;
                const startTime = this.leaveForm.startDate;
                const endTime = this.leaveForm.endDate;

                const data = {
                    requestedStart: moment(startTime).format('yyyy-MM-DD'),
                    requestedEnd: moment(endTime).format('yyyy-MM-DD'),
                    type: this.leaveForm.leaveType,
                    reason: this.leaveForm.reason ? this.leaveForm.reason : '',
                    mode: this.leaveForm.mode,
                };

                postLeaves(data)
                    .then(() => {
                        this.$Message.success('Request leave submitted');
                        this.isLoading = false;
                        this.$emit('create');
                        this.showModal = false;
                        this.$refs.form.resetFields();
                    })
                    .catch((err) => {
                        console.log('err: ', err.data);
                        this.isLoading = false;
                        this.$Message.error(err.data.message[0] || 'Internal Server Error');
                    });
            }
        },

        openModal(data, fromShiftTable) {
            this.attendanceLogData = data;

            this.isDisabled = !!fromShiftTable;
            this.isLoading = false;
            this.showModal = true;
        },
        handleCancel() {
            this.isLoading = false;
            this.showModal = false;
            this.$refs.leaveForm.resetFields();
        },

        handleConfirm() {
            this.showModal = false;
        },

        handleReset() {
            this.$refs.leaveForm.resetFields();
        },
        onDateStartChange(date) {
            this.leaveForm.startDate = new Date(date);
            if (!date) {
                this.leaveForm.startDate = '';
            }
            if (!this.leaveForm.endDate || this.leaveForm.startDate > this.leaveForm.endDate) {
                this.leaveForm.endDate = new Date(date);
            }
            if (this.leaveForm.mode !== '' && this.leaveForm.mode !== 'FULL') {
                this.leaveForm.endDate = new Date(date);
            }
        },
        onDateEndChange(date) {
            if (!date) {
                this.leaveForm.endDate = '';
            } else {
                if (!this.leaveForm.startDate || this.leaveForm.startDate > this.leaveForm.endDate) {
                    this.leaveForm.startDate = new Date(date);
                }
                this.leaveForm.endDate = new Date(date);
            }
        },
    },
};
</script>
<style lang="scss">
form.ivu-form.ivu-form-label-left.ivu-form-inline {
    border: unset;
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}
.createRequest .ivu-modal-body {
    padding: 16px;
}
.createRequest .ivu-modal-footer {
    display: none;
}
.createRequest .ivu-spin-main {
    justify-content: center;
    display: flex;
}
.create-time-dispute-wrapper {
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}

@media screen and (max-width: 767px) {
    .create-time-dispute-wrapper {
        .ivu-form-item {
            margin: 20px 5px;

            .ivu-form-item-content {
                margin-left: 0 !important;
                display: block !important;
            }
        }
    }
}
</style>
