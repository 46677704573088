<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="Edit Leaves"
            width="600"
            no-footer
            :closable="closable"
            class="updateRequest"
            :before-close="handleReset"
        >
            <Form
                ref="form"
                :model="form"
                :loading="isLoading"
                label-position="left"
                class="create-time-dispute-wrapper"
            >
                <Row>
                    <Col span="">
                        <FormItem label="Leave Mode">
                            <Select
                                v-model="form.mode"
                                style="width: 200px"
                                :value="form.mode"
                                default-label="FULL DAY"
                                @on-change="changeMode"
                            >
                                <Option v-for="item in leaveModes()" :key="item.value" :value="item.value">{{
                                    item.label
                                }}</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
                <Row v-if="form.mode === 'FULL'">
                    <Col span="12">
                        <FormItem label="Start Date" prop="startDate">
                            <DatePicker
                                v-model="form.startDate"
                                type="date"
                                format="dd-MMM-yyyy"
                                @on-change="onDateStartChange"
                            ></DatePicker>
                        </FormItem>
                    </Col>
                    <Col>
                        <FormItem label="End Date" prop="endDate">
                            <DatePicker
                                v-model="form.endDate"
                                format="dd-MMM-yyyy"
                                transfer
                                :loading="isLoading"
                                @on-change="onDateEndChange"
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row v-if="form.mode !== 'FULL'">
                    <Col span="12">
                        <FormItem label="Date" prop="startDate">
                            <DatePicker
                                v-model="form.startDate"
                                type="date"
                                format="dd-MMM-yyyy"
                                @on-change="onDateStartChange"
                            ></DatePicker>
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span="12">
                        <FormItem label="Leave Type" prop="requestedStartTime">
                            <v-radio-group v-model="form.leaveType">
                                <v-radio value="vl">
                                    <template slot="label">
                                        &nbsp;Vacation Leave&nbsp;<b>({{ leaves.vl }})</b>
                                    </template>
                                </v-radio>
                                <v-radio value="sl">
                                    <template slot="label">
                                        &nbsp;Emergency/Sick Leave&nbsp;<b>({{ leaves.sl }})</b>
                                    </template></v-radio
                                >
                                <v-radio label="Appreciation Leave" value="al"
                                    ><template slot="label">
                                        &nbsp;Appreciation Leave&nbsp;<b>({{ leaves.al }})</b>
                                    </template></v-radio
                                >
                            </v-radio-group>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="Reason for request (optional)">
                            <Input
                                v-model="form.reason"
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 5 }"
                                placeholder="Enter something..."
                                style="width: 400px"
                            />
                        </FormItem>
                    </Col>
                </Row>
                <div>
                    <Button class="pt-btn-2" style="margin-right: 10px" @click="handleCancel"> CANCEL </Button>
                    <Button class="pt-btn-3" :loading="isLoading" @click="handleUpdateLeave"> SUBMIT </Button>
                </div>
            </Form>
        </Modal>
    </div>
</template>
<script>
import { changeLeave } from '../../../../../services/requests.services';
import { getLeavesInfo } from '../../../../../services/leave.services';
import moment from 'moment';
import { canHalfDayLeave, leaveModes } from '@/components/Requests/Dev/Leave/LeaveRequest/utils/helper.ts';

export default {
    data() {
        return {
            attendanceLogData: null,
            requestData: null,
            isLoading: false,
            closable: false,
            form: {
                id: null,
                createdAt: null,
                startDate: null,
                endDate: null,
                leaveType: '',
                reason: '',
                mode: '',
            },
            leaves: {
                vl: 0,
                sl: 0,
                al: 0,
            },
            ruleInline: {
                requestedStart: [
                    {
                        required: true,
                        message: 'Please fill in start time',
                        trigger: 'change',
                    },
                ],
                requestedEnd: [
                    {
                        required: true,
                        message: 'Please fill in end time',
                        trigger: 'change',
                    },
                ],
            },
            showModal: false,
        };
    },
    methods: {
        canEmployeeHalfDayLeave(mode, start, end) {
            return canHalfDayLeave(mode, start, end);
        },
        leaveModes() {
            return leaveModes;
        },
        async handleUpdateLeave() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.updateLeave();
                } else {
                    this.$Message.error('Fill in the required fields');
                }
            });
        },

        updateLeave() {
            this.isLoading = true;

            const data = {
                requestedStart: moment(this.form.startDate).format('yyyy-MM-DD'),
                requestedEnd: moment(this.form.endDate).format('yyyy-MM-DD'),
                type: this.form.leaveType,
                reason: this.form.reason ? this.form.reason : '',
                mode: this.form.mode,
            };
            changeLeave({ id: this.form.id, data })
                .then(() => {
                    this.$Message.success('Request time dispute successfully updated');
                    this.isLoading = false;
                    this.$emit('create');
                    this.showModal = false;
                    this.$refs.form.resetFields();
                })
                .catch((err) => {
                    if (
                        err.data.statusCode === 400 &&
                        err.data.message === 'Request start time value should not be ahead/or equal to the end time'
                    ) {
                        this.$Message.error('Request start time value should not be ahead/or equal to the end time');
                    } else if (
                        err.data.statusCode === 400 &&
                        err.data.message ===
                            'You`ve already requested time dispute for the specified date. Please try to update it instead.'
                    ) {
                        this.$Message.error(
                            'You`ve already requested time dispute for the specified date. Please try to update it instead.'
                        );
                    } else {
                        this.$Message.error('Failed to add request time dispute');
                    }
                    this.isLoading = false;
                });
        },
        async populateLeavesInfo() {
            const { data } = await getLeavesInfo();
            this.leaves.sl = data['remaining-sick-leave'];
            this.leaves.al = data['remaining-appreciation-leave'];
            this.leaves.vl = data['remaining-vacation-leave'];
        },
        async openModal(data, fromShiftTable) {
            console.log('openModalData: ', data);
            await this.populateLeavesInfo();
            if (fromShiftTable) {
                this.form = {
                    id: data._id,
                    createdAt: data.createdAt,
                    startDate: new Date(data.requestedStart),
                    endDate: new Date(data.requestedEnd),
                    reason: data.reason,
                    leaveType: data.requestedData.type,
                    mode: data.requestedData.mode,
                };
            } else {
                this.requestData = data;
                this.form = {
                    id: data._id,
                    createdAt: data.createdAt,
                    startDate: new Date(data.requestedStart),
                    endDate: new Date(data.requestedEnd),
                    reason: data.reason,
                    leaveType: data.requestedData.type,
                    mode: data.requestedData.mode,
                };
            }
            this.isLoading = false;
            this.showModal = true;
        },

        handleCancel() {
            this.isLoading = false;
            this.showModal = false;
        },

        handleConfirm() {
            this.showModal = false;
        },

        handleReset() {
            this.$refs.form.resetFields();
        },
        onDateStartChange(date) {
            this.form.startDate = new Date(date);
            if (!date) {
                this.form.startDate = '';
            }
            if (!this.form.endDate || this.form.startDate > this.form.endDate) {
                this.form.endDate = new Date(date);
            }
            if (this.form.mode !== '' && this.form.mode !== 'FULL') {
                this.form.endDate = new Date(date);
            }
        },
        onDateEndChange(date) {
            if (!date) {
                this.form.endDate = '';
            } else {
                if (!this.form.startDate || this.form.startDate > this.form.endDate) {
                    this.form.startDate = new Date(date);
                }
                this.form.endDate = new Date(date);
            }
        },
        changeMode(mode) {
            if (mode !== 'FULL') {
                this.form.endDate = new Date(this.form.startDate);
            }
        },
    },
};
</script>
<style lang="scss">
form.ivu-form.ivu-form-label-left.ivu-form-inline {
    border: unset;
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}
.updateRequest .ivu-modal-body {
    padding: 16px;
}
.updateRequest .ivu-modal-footer {
    display: none;
}
.updateRequest .ivu-spin-main {
    justify-content: center;
    display: flex;
}
.update-time-dispute-wrapper {
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}
@media screen and (max-width: 767px) {
    .update-time-dispute-wrapper {
        .ivu-form-item {
            margin: 20px 5px;

            .ivu-form-item-content {
                margin-left: 0 !important;
                display: block !important;
            }
        }
    }
}
</style>
