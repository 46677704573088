<template>
    <div class="requests-filter">
        <Card dis-hover>
            <Row>
                <Col :xs="2" :sm="4" :md="6" :lg="1" class="d-flex-center">
                    <Card class="page-title" dis-hover :bordered="false"> Leave </Card>
                </Col>
            </Row>
            <Row type="flex">
                <Col :xs="2" :sm="4" :md="4" :lg="4" class="d-flex-center status-col">
                    <Select
                        v-model="selectedStatus"
                        :value="$route.query.status"
                        filterable
                        clearable
                        placeholder="Filter by"
                        @on-change="handleStatusChanged"
                    >
                        <Option v-for="item in requestStatus" :key="item.value" :value="item.value">
                            {{ item.name }}
                        </Option>
                    </Select>
                </Col>
                <Col :xs="2" :sm="4" :md="4" :lg="4" class="d-flex-center status-col">
                    <DatePicker
                        v-model="leaveDateFilter"
                        placeholder="Filter by date"
                        type="daterange"
                        format="MM-dd-yyyy"
                        @on-change="onDateChange"
                    ></DatePicker>
                </Col>
                <Col :xs="2" :sm="2" :md="3" :lg="3" class="status-col">
                    <Select
                        v-model="selectedFilter"
                        filterable
                        clearable
                        placeholder="leave type to filter"
                        @on-change="handleFilterChanged"
                    >
                        <Option v-for="item in filterType" :key="item.value" :value="item.value">
                            {{ item.name }}
                        </Option>
                    </Select>
                </Col>
            </Row>
        </Card>
    </div>
</template>

<script>
import Bugsnag from '@bugsnag/js';
import moment from 'moment';
// import moment from 'moment';
export default {
    props: {
        selectedDates: {
            default: null,
        },
    },
    data() {
        return {
            selectedStatus: this.$route.query.status,
            leaveDateFilter: null,
            filterType: [
                { name: 'Date Created', value: 'createdAt' },
                { name: 'Leave Start', value: 'requestedStart' },
            ],
            selectedFilter: 'createdAt',
        };
    },
    created() {
        if (this.$route.query.status) {
            this.$emit('status-changed', this.selectedStatus);
        }
        this.leaveDateFilter = [
            moment(this.selectedDates.from).format('MM-DD-YYYY'),
            moment(this.selectedDates.to).format('MM-DD-YYYY'),
        ];
    },
    methods: {
        handleStatusChanged(stats) {
            this.$emit('status-changed', stats);
            try {
                this.$router.replace({ query: null }).catch(() => {});
            } catch (e) {
                Bugsnag.notify(e);
            }
        },
        handleFilterChanged(stats) {
            this.$emit('filter-changed', stats);
            try {
                this.$router.replace({ querys: null }).catch(() => {});
            } catch (e) {
                Bugsnag.notify(e);
            }
        },
        onDateChange(date) {
            this.$emit('date-change', { from: date[0], to: date[1] });
        },
    },
};
</script>
<style lang="scss">
@media screen and (max-width: 767px) {
    .requests-filter {
        .ivu-row {
            display: block;

            .ivu-col {
                max-width: 100%;
            }
            .ivu-col-span-xs-2 {
                margin: 5px 0;
            }
            .filter-btn button {
                width: 100%;
            }
        }
    }
}
</style>
