<template>
    <div class="attendance-wrapper">
        <Row type="flex" justify="space-between" class="code-row-bg title-wrapper">
            <Col span="6">
                <Card class="page-title" dis-hover :bordered="false"> ATTENDANCE </Card>
            </Col>
        </Row>
        <Row type="flex" justify="space-between" style="margin-bottom: 20px">
            <Col class="d-flex-center">
                <span style="margin-right: 20px">Pay Period</span>
                <DatePicker
                    v-model="dates"
                    type="daterange"
                    :options="options"
                    placeholder="Select pay period date"
                    @on-change="handleDateFilter"
                />
                <Icon
                    style="margin-left: 10px"
                    size="30"
                    type="ios-information-circle"
                    title="The payroll period is limited to 2 weeks only. Please select either from 1 to 15 or from 16 to 30/31"
                />
                <span title="By using this arrow buttons. You can check 2 previous pay periods only.">
                    <button @click="onBackPayPeriod">
                        <Icon size="20" type="ios-arrow-back" />
                    </button>
                    <button @click="onForwardPayPeriod">
                        <Icon size="20" type="ios-arrow-forward" />
                    </button>
                </span>
            </Col>
            <Col class="d-flex-center">
                <Button class="pt-btn-3"> Export as CSV </Button>
            </Col>
        </Row>
        <AttendanceFilter style="margin-bottom: 20px" @status-changed="handleOnStatusChanged" />
        <AttendanceList
            v-if="(selectedStatus || selectedDates) && isFilterClick"
            :attendance-logs-list="attendanceLogsList"
            :is-loading="isLoading"
            :total-count="totalCount"
            :current-page="currentPage"
            :total-per-page="totalPerPage"
            @reload="handleReload"
            @on-page-change="handleOnPageChanged"
        />
    </div>
</template>

<script>
import { getPaginatedAttendanceRecords } from '@/services/attendancelogs.services';
import AttendanceFilter from '@/components/Attendance/Dev/AttendanceFilter';
import AttendanceList from '@/components/Attendance/Dev/AttendanceList';
import moment from 'moment';
import { checkIfFirstAttendancePeriod, endOfDate, startOfDate } from '../../utility/helper';

export default {
    components: {
        AttendanceFilter,
        AttendanceList,
    },
    data() {
        return {
            isLoading: false,
            attendanceLogsList: [],
            selectedStatus: this.$route.query.status,
            selectedDates: null,
            selectedUser: localStorage.getItem('user_id'),
            currentPage: 1,
            totalCount: null,
            isFilterClick: false,
            totalPerPage: {},
            dates: [null, null],
            options: null,
            flagBack: 0,
            flagForward: 0,
        };
    },
    created() {
        if (this.$route.query.status) {
            this.handleOnStatusChanged(this.$route.query.status);
        }
        this.options = {
            // disabledDate(date) {
            //     const checkMonthIfSame = moment().isSame(moment(date, 'MM'), 'months');
            //     if (checkMonthIfSame) {
            //         return checkIfFirstAttendancePeriod() ? date && date.getDate() > 15 : false;
            //     } else {
            //         return date && date.valueOf() > Date.now() - 86400000;
            //     }
            // },
        };
    },
    // mounted() {
    //   this.handleGetTimelogs()
    //   // this.handleGetProjectMembersList()
    //   this.handleGetProjectsList()
    // },

    methods: {
        handleGetAttendanceByStatus(status, date = null) {
            this.isLoading = true;
            this.attendanceLogsList = [];
            getPaginatedAttendanceRecords(
                this.selectedUser,
                { status: status, ...(date != null && { clockInTime: { $gte: date.from, $lte: date.to } }) },
                this.currentPage,
                15
            )
                .then((res) => {
                    if (res.data.data.length) {
                        this.isLoading = true;
                        this.totalCount = res.data.totalCount;
                        this.totalPerPage = res.data.total;
                        this.attendanceLogsList = res.data.data;
                    } else {
                        this.attendanceLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get attendance logs by status');
                    this.attendanceLogsList = [];
                    this.isLoading = false;
                });
        },
        checkPayPeriod() {
            const yearMonth = moment().format('yyyy-MM');
            console.log('checkIfDateFirstPeriod: ', checkIfFirstAttendancePeriod());
            const currentDate = moment().format('yyyy-MM-DD');
            return checkIfFirstAttendancePeriod()
                ? [`${yearMonth}-01`, `${yearMonth}-15`]
                : [`${yearMonth}-16`, moment(currentDate).endOf('month').format('yyyy-MM-DD')];
        },
        onBackPayPeriod() {
            console.log('before_clicked!!!');
            this.isFilterClick = true;
            if (this.flagBack <= 2) {
                this.flagBack += 1;
            }
            if (this.flagBack <= 2) {
                if (this.flagBack === 2) {
                    this.flagForward = 0;
                }
                if (!this.selectedDates) {
                    if (checkIfFirstAttendancePeriod()) {
                        const currMonth = moment().format('MM');
                        const prevMonth = moment().subtract(1, 'M').format('MM');
                        if (parseInt(currMonth) > 1 && parseInt(currMonth) <= 12) {
                            const currentYear = moment().format('yyyy');
                            const currentDay = moment().format('DD');
                            const endPeriod = moment(`${currentYear}-${prevMonth}-${currentDay}`)
                                .endOf('month')
                                .format('yyyy-MM-DD');
                            this.dates = [
                                `${currentYear}-${prevMonth}-16`,
                                moment(`${endPeriod}`).format('yyyy-MM-DD'),
                            ];
                            this.selectedDates = {
                                from: startOfDate(`${currentYear}-${prevMonth}-16`),
                                to: endOfDate(`${endPeriod}`),
                            };
                        } else {
                            const currentYear = moment().subtract(1, 'year').format('yyyy');
                            console.log(currentYear);
                            const currentDay = moment().format('DD');
                            const endPeriod = moment(`${currentYear}-${prevMonth}-${currentDay}`)
                                .endOf('month')
                                .format('yyyy-MM-DD');
                            this.dates = [
                                `${currentYear}-${prevMonth}-16`,
                                moment(`${endPeriod}`).format('yyyy-MM-DD'),
                            ];
                            this.selectedDates = {
                                from: startOfDate(`${currentYear}-${prevMonth}-16`),
                                to: endOfDate(`${endPeriod}`),
                            };
                        }
                    } else {
                        const currMonth = moment().format('MM');
                        if (parseInt(currMonth) > 1 && parseInt(currMonth) <= 12) {
                            const currentYear = moment().format('yyyy');
                            this.dates = [`${currentYear}-${currMonth}-01`, `${currentYear}-${currMonth}-15`];
                            this.selectedDates = {
                                from: startOfDate(`${currentYear}-${currMonth}-01`),
                                to: endOfDate(`${currentYear}-${currMonth}-15`),
                            };
                        } else {
                            const currentYear = moment().format('yyyy');
                            this.dates = [
                                `${currentYear}-${currMonth}-01`,
                                moment(`${currentYear}-${currMonth}-15`).format('yyyy-MM-DD'),
                            ];
                            this.selectedDates = {
                                from: startOfDate(`${currentYear}-${currMonth}-01`),
                                to: endOfDate(`${currentYear}-${currMonth}-15`),
                            };
                        }
                    }
                } else {
                    const selected = this.selectedDates;
                    const selFrmDate = moment(selected.from).format('DD');
                    const selToDate = moment(selected.to).format('DD');
                    console.log(selFrmDate, selToDate);
                    const checkIfDateFirstPeriod = parseInt(selFrmDate) === 1 && parseInt(selToDate) === 16;
                    console.log('checkIfDateFirstPeriod: ', checkIfDateFirstPeriod);
                    if (checkIfDateFirstPeriod) {
                        const currFrmMonth = moment(selected.from).format('MM');
                        const prevMonth = moment(selected.from).subtract(1, 'M').format('MM');
                        if (parseInt(currFrmMonth) > 1 && parseInt(currFrmMonth) <= 12) {
                            const currentYear = moment(selected.from).format('yyyy');
                            const currentDay = moment(selected.from).format('DD');
                            const endPeriod = moment(`${currentYear}-${prevMonth}-${currentDay}`)
                                .endOf('month')
                                .format('yyyy-MM-DD');
                            this.dates = [
                                `${currentYear}-${prevMonth}-16`,
                                moment(`${endPeriod}`).format('yyyy-MM-DD'),
                            ];
                            this.selectedDates = {
                                from: startOfDate(`${currentYear}-${prevMonth}-16`),
                                to: endOfDate(`${endPeriod}`),
                            };
                        } else {
                            const currentYear = moment(selected.from).subtract(1, 'year').format('yyyy');
                            const currentDay = moment(selected.from).format('DD');
                            const endPeriod = moment(`${currentYear}-${prevMonth}-${currentDay}`)
                                .endOf('month')
                                .format('yyyy-MM-DD');
                            this.dates = [
                                `${currentYear}-${prevMonth}-16`,
                                moment(`${endPeriod}`).format('yyyy-MM-DD'),
                            ];
                            this.selectedDates = {
                                from: startOfDate(`${currentYear}-${prevMonth}-16`),
                                to: endOfDate(`${endPeriod}`),
                            };
                        }
                    } else {
                        const currFrmMonth = moment(selected.from).format('MM');
                        if (parseInt(currFrmMonth) > 1 && parseInt(currFrmMonth) <= 12) {
                            const currentYear = moment(selected.from).format('yyyy');
                            this.dates = [`${currentYear}-${currFrmMonth}-01`, `${currentYear}-${currFrmMonth}-15`];
                            this.selectedDates = {
                                from: startOfDate(`${currentYear}-${currFrmMonth}-01`),
                                to: endOfDate(`${currentYear}-${currFrmMonth}-15`),
                            };
                        } else {
                            const currentYear = moment(selected.from).format('yyyy');
                            this.dates = [
                                `${currentYear}-${currFrmMonth}-01`,
                                moment(`${currentYear}-${currFrmMonth}-15`).format('yyyy-MM-DD'),
                            ];
                            this.selectedDates = {
                                from: startOfDate(`${currentYear}-${currFrmMonth}-01`),
                                to: endOfDate(`${currentYear}-${currFrmMonth}-15`),
                            };
                        }
                    }
                }
                this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
            }
        },
        onForwardPayPeriod() {
            console.log('forward_clicked!!!');
            this.isFilterClick = true;
            if (this.flagForward <= 2) {
                this.flagForward += 1;
            }
            if (this.flagForward <= 2) {
                if (this.flagForward === 2) {
                    this.flagBack = 0;
                }
                if (this.selectedDates) {
                    const selected = this.selectedDates;
                    const selFrmDate = moment(selected.from).format('DD');
                    const selToDate = moment(selected.to).format('DD');
                    console.log(selFrmDate, selToDate);
                    const checkIfDateFirstPeriod = parseInt(selFrmDate) === 1 && parseInt(selToDate) === 16;
                    console.log('checkIfDateFirstPeriod', checkIfDateFirstPeriod);
                    if (checkIfDateFirstPeriod) {
                        const currFrmMonth = moment(selected.from).format('MM');
                        const currentFrmYear = moment(selected.from).format('yyyy');
                        const currentFrmDay = moment(selected.from).format('DD');
                        const endPeriod = moment(`${currentFrmYear}-${currFrmMonth}-${currentFrmDay}`)
                            .endOf('month')
                            .format('yyyy-MM-DD');
                        this.dates = [
                            `${currentFrmYear}-${currFrmMonth}-16`,
                            moment(`${endPeriod}`).format('yyyy-MM-DD'),
                        ];
                        this.selectedDates = {
                            from: startOfDate(`${currentFrmYear}-${currFrmMonth}-16`),
                            to: endOfDate(`${endPeriod}`),
                        };
                    } else {
                        const currFrmMonth = moment(selected.from).format('MM');
                        const nextMonth = moment(selected.from).add(1, 'M').format('MM');
                        console.log('nextMonth', nextMonth);
                        if (parseInt(currFrmMonth) >= 1 && parseInt(currFrmMonth) < 12) {
                            const currentFrmYear = moment(selected.from).format('yyyy');
                            this.dates = [`${currentFrmYear}-${nextMonth}-01`, `${currentFrmYear}-${nextMonth}-15`];
                            this.selectedDates = {
                                from: startOfDate(`${currentFrmYear}-${nextMonth}-01`),
                                to: endOfDate(`${currentFrmYear}-${nextMonth}-15`),
                            };
                        } else {
                            const currentFrmYear = moment(selected.from).add(1, 'year').format('yyyy');
                            this.dates = [
                                `${currentFrmYear}-${nextMonth}-01`,
                                moment(`${currentFrmYear}-${nextMonth}-15`).format('yyyy-MM-DD'),
                            ];
                            this.selectedDates = {
                                from: startOfDate(`${currentFrmYear}-${nextMonth}-01`),
                                to: endOfDate(`${currentFrmYear}-${nextMonth}-15`),
                            };
                        }
                    }
                }
                this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
            }
        },
        handleOnFilter(date) {
            this.selectedDates = date;
            this.isFilterClick = true;
            this.currentPage = 1;
            this.handleGetAttendanceLogsByUser(this.selectedUser, date);
        },
        handleOnStatusChanged(status) {
            this.selectedStatus = status;
            this.isFilterClick = true;
            if (!this.selectedDates) {
                const actualDatePeriod = this.checkPayPeriod();
                this.selectedDates = {
                    from: startOfDate(actualDatePeriod[0]),
                    to: endOfDate(actualDatePeriod[1]),
                };
            }
            if (status) {
                this.handleGetAttendanceByStatus(status, this.selectedDates);
            } else {
                this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
            }
        },
        handleReload() {
            if (this.selectedStatus && this.selectedDates) {
                this.handleGetAttendanceByStatusAndUser(this.selectedStatus, this.selectedUser, this.selectedDates);
            } else if (this.selectedStatus) {
                this.handleGetAttendanceByStatus(this.selectedStatus, this.selectedDates);
            } else if (this.selectedDates) {
                this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
            }
        },
        handleGetAttendanceByStatusAndUser(status, userId, date = null) {
            this.isLoading = true;
            this.attendanceLogsList = [];
            getPaginatedAttendanceRecords(
                userId,
                {
                    status: status,
                    ...(date != null && { clockInTime: { $gte: date.from, $lte: date.to } }),
                },
                this.currentPage,
                15
            )
                .then((res) => {
                    if (res.data.data.length) {
                        this.totalCount = res.data.totalCount;
                        this.totalPerPage = res.data.total;
                        this.attendanceLogsList = res.data.data;
                    } else {
                        this.attendanceLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get attendance logs by status and user');
                    this.attendanceLogsList = [];
                    this.isLoading = false;
                });
        },
        handleGetAttendanceLogsByUser(userId, date = null) {
            this.isLoading = true;
            this.attendanceLogsList = [];
            getPaginatedAttendanceRecords(
                userId,
                { ...(date != null && { clockInTime: { $gte: date.from, $lte: date.to } }) },
                this.currentPage,
                15
            )
                .then((res) => {
                    if (res.data.data.length) {
                        this.totalCount = res.data.totalCount;
                        this.totalPerPage = res.data.total;
                        this.attendanceLogsList = res.data.data;
                    } else {
                        this.attendanceLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get attendance logs by user');
                    this.attendanceLogsList = [];
                    this.isLoading = false;
                });
        },
        handleDateFilter() {
            const calculateDays = moment(this.dates[1]).diff(this.dates[0], 'days');
            if (this.dates[0] && this.dates[1]) {
                if (this.dates[0] == null) {
                    this.$Message.error('Provide Date');
                } else if (calculateDays > 15) {
                    this.$Message.error('Days should be less than 16 days');
                } else {
                    this.handleOnFilter({
                        from: startOfDate(this.dates[0]),
                        to: endOfDate(this.dates[1]),
                    });
                }
            } else {
                this.handleOnStatusChanged(this.selectedStatus);
            }
        },
        handleOnPageChanged(page) {
            if (this.selectedStatus && this.selectedDates) {
                this.currentPage = page;
                this.handleGetAttendanceByStatusAndUser(this.selectedStatus, this.selectedUser, this.selectedDates);
            } else if (this.selectedStatus) {
                this.currentPage = page;
                this.handleGetAttendanceByStatus(this.selectedStatus, this.selectedDates);
            } else if (this.selectedDates) {
                this.currentPage = page;
                this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
            }
        },
    },
};
</script>
<style lang="scss">
.attendance-wrapper {
    .ivu-col {
        button {
            border-radius: 15px;
        }
    }
    .title-wrapper {
        display: block;

        .ivu-col {
            max-width: 100% !important;
        }
        .text-right {
            text-align: left;
            padding-top: 0 !important;
        }
    }
}
</style>
